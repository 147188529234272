var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-func-input-wrap"},[(_vm.title)?_c('div',{staticClass:"c-func-input-title"},[_c('texts',{attrs:{"text":_vm.title,"size":"small","color":"gray"}}),(_vm.errorReason)?_c('texts',{staticClass:"c-func-input-error-reason",attrs:{"text":_vm.errorReason,"size":"small","color":"caution"}}):_vm._e()],1):_vm._e(),(!_vm.isGray)?_c('div',{staticClass:"c-func-input"},[[(_vm.isSearch)?_c('icon',{staticClass:"c-func-input-icon",attrs:{"iconName":"search","size":"small"}}):_vm._e(),(_vm.number || _vm.type === 'number')?_c('input',{ref:"editBox",staticClass:"c-func-input-inner",class:{
          search: _vm.isSearch,
          required: _vm.required && _vm.value == null,
          'c-func-input-inner-error': _vm.error
        },attrs:{"type":"number","placeholder":_vm.placeholder,"max":_vm.max,"min":_vm.forceStep ? null : _vm.min,"step":_vm.step,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)},"change":function($event){return _vm.updateValueChange($event)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')}}}):_c('input',{ref:"editBox",staticClass:"c-func-input-inner",class:{
          search: _vm.isSearch,
          required: _vm.required && _vm.value == null,
          'c-func-input-inner-error': _vm.error
        },attrs:{"placeholder":_vm.placeholder,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)},"change":function($event){return _vm.updateValueChange($event)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')}}})],_c('div',{staticClass:"c-func-underline"})],2):_c('div',{staticClass:"c-func-input"},[(_vm.isSearch)?_c('icon',{staticClass:"c-func-input-icon",attrs:{"iconName":"search","size":"small"}}):_vm._e(),(!_vm.isTextArea)?[(_vm.number || _vm.type === 'number')?_c('input',{ref:"editBox",staticClass:"c-func-input-inner-gray",class:{
          search: _vm.isSearch,
          required: _vm.required && _vm.value == null,
          'c-func-input-inner-error': _vm.error
        },attrs:{"type":"number","max":_vm.max,"min":_vm.forceStep ? null : _vm.min,"step":_vm.step,"placeholder":_vm.placeholder,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)},"change":function($event){return _vm.updateValueChange($event)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')}}}):_c('input',{staticClass:"c-func-input-inner-gray",class:{
          search: _vm.isSearch,
          required: _vm.required && _vm.value == null,
          'c-func-input-inner-error': _vm.error
        },attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)},"change":function($event){return _vm.updateValueChange($event)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')}}})]:_c('textarea',{staticClass:"c-func-input-textarea",class:{
        search: _vm.isSearch,
        required: _vm.required && _vm.value == null
      },attrs:{"placeholder":_vm.placeholder,"rows":_vm.rows,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)},"change":function($event){return _vm.updateValueChange($event)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')}}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }